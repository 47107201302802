<template>
    <div class="add_update_role">
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogTabelWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :disabled="isCheck" :label-width="formLabelWidth">
                    <div class="periodTimeTip" v-text="periodTimeText" :style="{'margin-left':formLabelWidth}"></div>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item prop="routeName" label="线路组织:">
                                <div>
                                    <el-input placeholder="请选择" :disabled="isUpdate||isCheck" v-model="diaform.routeName" @focus="selectInstitutions"></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="选择时段:" prop="timeSeg">
                                <el-select v-model="diaform.timeSeg" placeholder="请选择" @change="periodTimeChange" :disabled="isUpdate||isCheck">
                                    <el-option v-for="item in periodTimeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="日期类型:" prop="dateType">
                                <el-radio v-model="diaform.dateType" label="1" @change="dateTypeChange" :disabled="isUpdate||isCheck">全部</el-radio>
                                <el-radio v-model="diaform.dateType" label="2" @change="dateTypeChange" :disabled="isUpdate||isCheck">工作日</el-radio>
                                <el-radio v-model="diaform.dateType" label="3" @change="dateTypeChange" :disabled="isUpdate||isCheck">周末</el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12" v-if="!isCheck">
                            <el-form-item label="方向:" prop="fangxiang">
                                <el-select v-model="diaform.fangxiang" placeholder="请选择" :disabled="isUpdate||isCheck">
                                    <el-option v-for="item in directionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="滞站时长：大于" v-if="!isCheck">
                        <el-col :span="7">
                            <el-form-item prop="ztime">
                                <el-input type="number" v-model="diaform.strandTime">
                                    <template slot="append">秒</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">为滞站</el-col>
                        <el-col :span="1">小于</el-col>
                        <el-col :span="7">
                            <el-form-item prop="skipTime">
                                <el-input type="number" v-model="diaform.skipTime">
                                    <template slot="append">秒</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="5">为越站</el-col>
                    </el-form-item>
                    <el-row class="search" v-if="!isCheck">
                        <el-col :span="24" class="search_button">
                            <el-row>
                                <el-col :span="24">
                                    <el-button type="primary" v-on:click="batchSetting()">批量设置</el-button>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-tabs v-model="activeName" class="jy_tabs">
                        <el-tab-pane label="上行" name="first">
                            <jy-table :data="updataList" header-cell-class-name="bg_color_2" height="250" v-loading="loading">
                                <jy-table-column type="index" label="序号" width="90"></jy-table-column>
                                <jy-table-column prop="siteNo" label="站点序号" min-width="120"></jy-table-column>
                                <jy-table-column prop="name" label="站点名称" min-width="120"></jy-table-column>
                                <jy-table-column prop="strandTime" label="滞站时间(s)" min-width="120">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.strandTime" clearable></el-input>
                                    </template>
                                </jy-table-column>
                                <jy-table-column prop="skipTime" label="越站时间(s)" min-width="120">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.skipTime" clearable></el-input>
                                    </template>
                                </jy-table-column>
                            </jy-table>
                        </el-tab-pane>
                        <el-tab-pane label="下行" name="second">
                            <jy-table :data="downdataList" header-cell-class-name="bg_color_2" height="250" v-loading="loading">
                                <jy-table-column type="index" label="序号" width="90"></jy-table-column>
                                <jy-table-column prop="siteNo" label="站点序号" min-width="120"></jy-table-column>
                                <jy-table-column prop="name" label="站点名称" min-width="120"></jy-table-column>
                                <jy-table-column prop="strandTime" label="滞站时间(s)" min-width="120">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.strandTime" clearable></el-input>
                                    </template>
                                </jy-table-column>
                                <jy-table-column prop="skipTime" label="越站时间(s)" min-width="120">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.skipTime" clearable></el-input>
                                    </template>
                                </jy-table-column>
                            </jy-table>
                        </el-tab-pane>
                    </el-tabs>
                    <el-form-item label="备注:" prop="remark">
                        <el-input v-model="diaform.remark" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="!isCheck">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save('dialogForm')">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    data() {
        return {
            type: '',
            isCheck: false,
            title: '',
            periodTimeList: [{
                    value: '1',
                    label: '全天'
                },
                {
                    value: '2',
                    label: '工作日'
                },
                {
                    value: '3',
                    label: '周末'
                }
            ],
            periodTimeText: '',
            activeName: 'first',
            updataList: [],
            downdataList: [],
            props: {
                children: 'children',
                label: 'text',
                value: 'id',
                // multiple: true,
                checkStrictly: true
            },
            directionList: [{
                    value: '',
                    label: '全部'
                },
                {
                    value: 1,
                    label: '上行'
                },
                {
                    value: 2,
                    label: '下行'
                }
            ],
            diaform: {
                dateType: '1',
                routeId: '',
                routeName: '',
                siteId: '',
                skipTime: 0,
                strandSkipSiteSetId: '',
                strandTime: 0,
                timeSeg: '1',
                remark: '',
                fangxiang: ''
            },
            siteForm: {},
            rules: {
                timeSeg: [{
                    required: true,
                    message: '请选择时段',
                    trigger: ['blur', 'change']
                }],
                routeName: [{
                    required: true,
                    message: '请选择线路组织',
                    trigger: ['blur', 'change']
                }]
            },
            dialogVisible: false,
            formLabelWidth: '150px',
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            loading: true,
            isUpdate: false,
        }
    },
    components: { institutionsTree },
    activated() {},
    created() {},
    watch: {
        type: function(val) {
            let t = ''
            let isCheck = false
            switch (val) {
                case 'add':
                    t = '新增滞站越站参数设置'
                    break
                case 'update':
                    t = '修改滞站越站参数设置'
                    this.isUpdate = true;
                    break
                case 'view':
                    t = '查看滞站越站参数设置'
                    isCheck = true
                    break
                default:
                    t = '新增滞站越站参数设置'
            }
            this.isCheck = isCheck
            this.title = t
        },
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
                this.periodTimeText = '';
                this.isUpdate = false;
                this.type = '';
            }
        }
    },
    methods: {
        init(option, type) {
            this.type = type ? type : 'add'
            this.dialogVisible = true
            if (option) {
                this.$nextTick(() => {
                    if (this.type != 'add') {
                        //更新或查看
                        this.diaform = JSON.parse(JSON.stringify(option))
                        this.siteForm = JSON.parse(JSON.stringify(option))
                        this.getUpDowndata()
                        this.periodTimeChange()
                    } else {
                        //新增
                        this.siteForm = JSON.parse(JSON.stringify(option))
                        this.diaform.routeId = this.siteForm.routeId
                        this.diaform.routeName = this.siteForm.routeName
                        this.getUpDowndata_add()
                    }
                })
            }
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        addInstitutions(data) {
            console.log(data)
            this.diaform.routeId = data.id
            this.diaform.routeName = data.text
            if (this.type == 'update') {
                this.siteForm = this.diaform
                this.getUpDowndata()
            } else {
                this.siteForm = this.diaform
                this.getUpDowndata_add()
            }
        },
        // 选择时段
        periodTimeChange() {
            // 提示信息显示
            //   let option = {}
            //   let url = '/system/org/queryOrgTree'
            //   this.$http.post(url, option).then(({ detail }) => {
            //     if (detail) {
            //       this.periodTimeText = detail
            //     } else {
            //       this.$message({
            //         type: 'warning',
            //         message: '【线路管理】早高峰时间段未设置，请设置！'
            //       })
            //     }
            //   })
            //   if (parseInt(this.diaform.timeSeg) == 2) {
            //     this.periodTimeText = '早高峰开始时间 06:30，早高峰结束时间 10:00'
            //   } else if (parseInt(this.diaform.timeSeg) == 3) {
            //     this.periodTimeText = '晚高峰开始时间 18:30，晚高峰结束时间 20:00'
            //   } else if (parseInt(this.diaform.timeSeg) == 4) {
            //     this.periodTimeText = '低峰开始时间 10:00，低峰结束时间 18:30'
            //   }
            //   根据时段重新查询上下行数据
            if (this.type == 'update') {
                this.siteForm = this.diaform
                this.getUpDowndata()
            }
        },
        // 选择日期类型
        dateTypeChange() {
            if (this.type == 'update') {
                this.siteForm = this.diaform
                this.getUpDowndata()
            }
        },
        // 上行下行数据（修改时）
        getUpDowndata() {
            let option = {
                ...this.siteForm,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            //   console.log(option)
            let url = '/psstrandskipsiteset/queryStrandSkipSiteInfoByRId'
            this.$http.post(url, option).then(
                ({ detail }) => {
                    this.updataList = detail.upStreamSiteList
                    this.downdataList = detail.downStreamSiteList
                    this.loading = false
                },
                () => {
                    this.loading = false
                }
            )
        },
        // 上行下行数据（新增时）
        getUpDowndata_add() {
            let option = {
                ...this.siteForm,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            let url = '/psstrandskipsiteset/querySiteIdNameByRId'
            this.$http.post(url, option).then(
                ({ detail }) => {
                    this.updataList = detail.upStreamSiteList
                    this.downdataList = detail.downStreamSiteList
                    this.loading = false
                },
                () => {
                    this.loading = false
                }
            )
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        // 批量设置
        batchSetting() {
            if (parseInt(this.diaform.fangxiang) == 1) {
                this.updataList.forEach(item => {
                    item.strandTime = this.diaform.strandTime
                    item.skipTime = this.diaform.skipTime
                })
                this.updataList = JSON.parse(JSON.stringify(this.updataList))
            } else if (parseInt(this.diaform.fangxiang) == 2) {
                this.downdataList.forEach(item => {
                    item.strandTime = this.diaform.strandTime
                    item.skipTime = this.diaform.skipTime
                })
                this.downdataList = JSON.parse(JSON.stringify(this.downdataList))
            } else {
                this.updataList.forEach(item => {
                    item.strandTime = this.diaform.strandTime
                    item.skipTime = this.diaform.skipTime
                })
                this.downdataList.forEach(item => {
                    item.strandTime = this.diaform.strandTime
                    item.skipTime = this.diaform.skipTime
                })
                this.updataList = JSON.parse(JSON.stringify(this.updataList))
                this.downdataList = JSON.parse(JSON.stringify(this.downdataList))
            }
        },
        save(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let updataList = JSON.parse(JSON.stringify(this.updataList))
                    let downdataList = JSON.parse(JSON.stringify(this.downdataList))
                    let totalList = updataList.concat(downdataList)
                    totalList.forEach(item => {
                        item.dateType = this.diaform.dateType
                        item.routeId = this.diaform.routeId
                        item.timeSeg = this.diaform.timeSeg
                        item.remark = this.diaform.remark
                    })
                    console.log(totalList)
                    let url = '/psstrandskipsiteset/'
                    if (this.type == 'add') {
                        url = url + 'save'
                    } else {
                        url = url + 'update'
                    }
                    this.$http.post(url, totalList).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}

</script>
<style lang='scss' scoped>
.periodTimeTip {
    color: red;
    margin-bottom: 10px;
}

.timeText {
    display: inline-block;
    margin-right: 30px;
}

.jy_tabs {
    padding: 10px 0px 50px 20px;
}

.search {
    margin-right: 100px;
}

</style>
